import mutations from './mutations'

const state = () => ({
    warehousesMap: [],
})
  
const actions = {
    async changeWarehousesMap({ commit }, value) {
      try {
        commit('changeWarehousesMap', value)
      } catch (err) {
        console.log('commit changeWarehousesMap error!!!')
      }
    },
}

export default {
    namespaced: false,
    state,
    actions,
    mutations,
}
  