<template>
    <sdButton type="white" :raised="true" @click="showDrawer" class="btn-drawer">
      <unicon :name="unicon"></unicon></sdButton>
    <a-drawer
      :title="title"
      :placement="placement"
      :closable="false"
      v-model:visible="visible"
    >
      <slot></slot>
    </a-drawer>
</template>

<script>
import VueTypes from "vue-types";
import { ref, onMounted, defineComponent } from "vue";
export default defineComponent({
  name: "Drawer",
  components: {
  },
  props: {
    title: VueTypes.string,
    placement: VueTypes.string,
    btnIcon: VueTypes.string,
  },
  setup(props) {
    const visible = ref(false)
    const unicon = ref(props.btnIcon)

    const onClose = () => {
      visible.value = false
    }
    const showDrawer = () => {
        visible.value = true
    }
    onMounted(() => {
      visible.value = false
    })

    return {
      visible,
      unicon,
      onClose,
      showDrawer
    };
  },
});
</script>

<style scoped>
.btn-drawer {
  background: none!important;
}
.btn-drawer:hover {
  background: none!important;
}
</style>