export default {
  loginBegin(state) {
    state.login = true;
  },
  loginSuccess(state, data) {
    state.loading = false;
    state.login = data;
  },

  loginErr(state, err) {
    state.loading = false;
    state.errorAuth = err;
  },

  logoutBegin(state) {
    state.loading = true;
  },

  logoutSuccess(state, data) {
    state.loading = false;
    state.login = data;
  },

  logoutErr(state, err) {
    state.loading = false;
    state.errorLogout = err;
  },
  registerSuccess(state, data) {
    state.loading = false;
    state.msgSuccess = data;
  },
  registerErr(state, err) {
    state.loading = false;
    state.msgError = err;
  },
  resetErrors(state) {
    state.msg = false;
    state.errorAuth = false;
    state.errorLogout = false;
  },
  setCSRF(state, data) {
    state.csrf = data
  }
};
