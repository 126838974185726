import app from '../../config/configApp';
import Cards from '../../components/cards/frame/CardsFrame.vue';
import BtnGroup from '../../components/buttons/ButtonGroup.vue';
import Button from '../../components/buttons/Buttons.vue';
import { PageHeader } from '../../components/pageHeaders/PageHeaders.vue';
import Heading from '../../components/heading/Heading';
import Dropdown from '../../components/dropdown/Dropdown';
import Popover from '../../components/popup/Popup.vue'
import Alerts from '../../components/alerts/Alerts.vue';
import SimpleInput from '../../components/inputs/SimpleInput.vue';
import CounterBtn from '../../components/buttons/CounterButton.vue';
import DataTable from '../../components/table/DataTable.vue';
import Fancybox from '../../components/fancybox/Fancybox.vue';
import Modals from '../../components/modals/Modals.vue';
import Drawer from '../../components/drawer/Drawer.vue';
//import DatePicker from '../../components/datePicker/DatePicker.vue';

[
  Heading,
  Cards,
  Alerts,
  SimpleInput,
  CounterBtn,
  DataTable,
  Drawer,
  {
    name: 'Button',
    ...Button,
  },
  {
    name: 'BtnGroup',
    ...BtnGroup,
  },
  { name: 'PageHeader', ...PageHeader },
  Dropdown,
  Popover,
  Fancybox,
  Modals
].map((c) => {
  app.component(`sd${c.name}`, c);
});
