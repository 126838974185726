import { createStore } from 'vuex';
import themeLayout from './modules/themeLayout/actionCreator';
import auth from './modules/authentication/axios/actionCreator';
import settings from './modules/settings/actionCreator';
import wbWaresouses from './modules/wb/warehousesSettings/actionCreator';

export default createStore({
  modules: {
      themeLayout,
      auth,
      settings,
      wbWaresouses
  },
});
