<template>
    <div class="counter-btn"><slot></slot></div>
</template>

<script>
    import { defineComponent } from 'vue'

    export default defineComponent({
        name: 'CounterBtn',
    })
</script>

<style>

</style>