import store from '@/vuex/store';
import axios from 'axios';

const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;

const authHeader = async() => ({

})

const client = axios.create({
  baseURL: API_ENDPOINT,
  withCredentials: true, //передача кук и других данных. Иначе будет создана новая сессия
  headers: {
    'Content-Type': 'application/json',
  },
});

class DataService {
  static async get(path = '') {
    return client({
      method: 'GET',
      url: path,
      headers: { ...authHeader() },
    });
  }

  static async post(path = '', data = {}, optionalHeader = {}, responseType) {
    return client({
      method: 'POST',
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader},
      responseType: responseType || 'json'
    });
  }

  static patch(path = '', data = {}) {
    return client({
      method: 'PATCH',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static delete(path = '', data = {}) {
    return client({
      method: 'DELETE',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static put(path = '', data = {}) {
    return client({
      method: 'PUT',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use(async(config) => {
  // do something before executing the request
  // For example tag along the bearer access token to request header or set a cookie
    const requestConfig = config;
    const { headers } = config;
    requestConfig.headers = { ...headers, 'X-CSRF-Token': store.state.auth.csrf}
    return requestConfig;
})

client.interceptors.response.use(
  (response) => response,
  async(error) => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    const { response } = error
    const originalRequest = error.config
    if (response?.status === 401) { //Не авторизован
      try {
        await store.dispatch('logOut')
        location.replace('/auth/login')
      } catch(err) {
        console.log('Ошибка axios interceptors: ', err)
      }
    } else if(response?.status === 403 && !originalRequest.sent) { //Обновление CSRF
      try {
        originalRequest.sent = true
        await store.dispatch('refreshCSRF')
        return client(originalRequest)
      } catch(err) {
        return Promise.reject(error)
      }
    } else {
      return Promise.reject(response)
    }

    return Promise.reject(error)
  }
)
export { DataService };
