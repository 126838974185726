//Этот стор нужен для отображения разделов в менюшке в зависимости от того, к каким разделам есть доступ
import mutations from './mutations'
import { DataService } from '@/config/dataService/dataService'

const state = () => ({
    userInfo: null,
})

const actions = {
  async userInfo({ commit }) {
    try {
      const res = await DataService.post('/personal/settings-info')
      if (res.status >= 200 && res.status < 300) {
        commit('updateUserInfo', res.data)
      }
    } catch (err) {
      console.log('Error userInfo')
    }
  },
}

export default {
  namespaced: false,
  state,
  actions,
  mutations,
}
