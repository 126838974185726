const routes = [
  {
    path: '',
    name: 'blank-page',
    component: () => import('@/view/pages/BlankPage.vue'),
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/view/pages/settings/Settings.vue'),
  },
  {
    path: '/integration',
    name: 'integration',
    component: () => import('@/view/pages/integration/Integration.vue'),
  },
  {
    path: '/bitrix-integration',
    name: 'bitrix-integration',
    component: () => import('@/view/pages/integration/BitrixIntegration'),
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('@/view/pages/products/Products.vue'),
  },
  {
    path: '/wb-qr-delivery',
    name: 'wb-qr-delivery',
    component: () => import('@/view/pages/wb/QrDelivery.vue'),
  },
  {
    path: '/wb-qr',
    name: 'wb-qr',
    component: () => import('@/view/pages/wb/WbQr.vue'),
  },
  {
    path: '/wb-pechat-shtrihkod',
    name: 'wb-pechat-shtrihkod',
    component: () => import('@/view/pages/wb/PechatShtrihkod.vue'),
  },
  {
    path: '/wb-qr-shtrihkod',
    name: 'wb-qr-shtrihkod',
    component: () => import('@/view/pages/wb/WbQrShtrihkod.vue'),
  },
  {
    path: '/wb-leftovers-fbo',
    name: 'wb-leftovers-fbo',
    component: () => import('@/view/pages/wb/LeftoversFbo.vue'),
  },
  {
    path: '/wb-supplies',
    name: 'wb-supplies',
    component: () => import('@/view/pages/wb/Supplies.vue'),
  },
  {
    path: '/wb-warehouses-settings',
    name: 'wb-warehouses-settings',
    component: () => import('@/view/pages/wb/WarehousesSettings.vue'),
  },
  {
    path: '/wb-fbs',
    name: 'wb-fbs',
    component: () => import('@/view/pages/wb/WbFbs.vue'),
  },
];

export default routes
