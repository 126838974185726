<template>
    <input type="text">
</template>

<script>
    import { defineComponent } from 'vue'

    export default defineComponent({
        name: 'SimpleInput',
    })
</script>

<style>

</style>