import mutations from './mutations'
import Cookies from 'js-cookie'
import { DataService } from '@/config/dataService/dataService'

const state = () => ({
  login: Cookies.get('logedIn'),
  loading: false,
  errorAuth: null,
  errorLogout: null,
  msgSuccess: null,
  msgError: null,
  csrf: null
})

const actions = {
  async refreshCSRF({ commit }) {
    try {
      const token = await DataService.get('/csrf-token')
      commit('setCSRF', token.data.csrfToken)
    } catch (err) {
      console.log('Error refresh CSRF Token')
    }
  },
  async loginFromRegister({ commit }) {
      Cookies.set('logedIn', true, { expires: 365 })
      commit('loginSuccess', true)
      commit('resetErrors')
  },
  async login({ commit }, payload) {
    try {
      const res = await DataService.post('/auth/login', payload)
      if (res.status >= 200 && res.status < 300) {
        Cookies.set('logedIn', true, { expires: 365 })
        commit('loginSuccess', true)
        commit('resetErrors')
      }
    } catch (err) {
      if (err.response) {// Обработка сетевых ошибок
        console.log('Ошибка авторизации: ', {err})
        commit('loginErr', '500. Ошибка сервера, обратитесь в техподдержку.')
      } else {// Обработка ошибки с сервера
        commit('loginErr', err.data.message)
      }
    }
  },
  async logOut({ commit }) {
    try {
      commit('logoutBegin')
      Cookies.remove('logedIn')
      commit('logoutSuccess', null)
    } catch (err) {
      commit('logoutErr', err)
    }
  },
  async register({ commit }, payload) {
    try {
      const res = await DataService.post('/auth/register', payload)
      if (res.status >= 200 && res.status < 300) {
        commit('registerSuccess', res.data.message)
        commit('resetErrors')
      }
    } catch (err) {
      if (err.response) {// Обработка сетевых ошибок
        console.log('Ошибка регистрации: ', {err})
        commit('registerErr', '500. Ошибка сервера, обратитесь в техподдержку.')
      } else {// Обработка ошибки с сервера
        commit('registerErr', err.data.error)
      }
    }
  },
}

export default {
  namespaced: false,
  state,
  actions,
  mutations,
}
