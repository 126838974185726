import { createWebHistory, createRouter } from 'vue-router';
import protectedRoutes from './protectedRoutes';
import publicRoutes from './publicRoutes';
import blog from './blog'
import store from '@/vuex/store';
const { state, dispatch } = store

const routes = [
  {
    name: 'Main',
    path: '/',
    component: () => import(/* webpackChunkName: "admin" */ '@/layout/Main.vue'),
    meta: { auth: true },
  },
  {
    name: 'Admin',
    path: '/personal',
    component: () => import(/* webpackChunkName: "admin" */ '@/layout/WithAdminLayout.vue'),
    children: [...protectedRoutes],
    meta: { auth: false },
  },
  {
    name: 'Auth',
    path: '/auth',
    component: () => import(/* webpackChunkName: "auth" */ '@/layout/WithAuthLayout.vue'),
    children: [...publicRoutes],
    meta: { auth: true },
  },
  {
    name: 'Blog',
    path: '/blog',
    component: () => import(/* webpackChunkName: "auth" */ '@/layout/BlogLayout.vue'),
    children: [...blog],
    meta: { auth: true, blog: true },
  },
];

const router = createRouter({
  history: createWebHistory(
    process.env.NODE_ENV === 'production'
      ? process.env.VUE_APP_SUB_ROUTE
        ? process.env.VUE_APP_SUB_ROUTE
        : process.env.BASE_URL
      : process.env.BASE_URL,
  ),
  linkExactActiveClass: 'active',
  routes,
});

router.beforeEach(async(to, from, next) => {
  console.log('to.meta: ', to.meta)
  if (to.meta.auth && state.auth.login) { //страницы /auth/* редиректят авторизованного в админку
    console.log('to.meta 1: ', to.meta)
    next('/personal')
  } else if (!to.meta.auth && !state.auth.login && !to.meta.blog) { //если попытка входа в админку неавторизованного
    console.log('to.meta 2: ', to.meta)
    next({ name: 'login' })
  } else { //если админ
    if(state.auth.login && !state.settings.userInfo) {
      await dispatch('userInfo')
    }
    next()
  }

  window.scrollTo(0, 0); // reset scroll position to top of page
})

export default router;
