const routes = [
    {
        path: '',
        name: 'blog-list',
        component: () => import('@/view/blog/BlogList.vue'),
    },
    {
        path: '/blog/products',
        name: 'products-b',
        component: () => import('@/view/blog/Products.vue'),
    },
    {
        path: '/blog/planirovanie-postavok',
        name: 'planirovanie-postavok',
        component: () => import('@/view/blog/PlanirovaniePostavok.vue'),
    },
    {
        path: '/blog/pechat-qr-kodov-whildberries',
        name: 'pechat-qr-kodov-whildberries',
        component: () => import('@/view/blog/PechatQr.vue'),
    },
    {
        path: '/blog/pechat-strihkodov-whildberries',
        name: 'pechat-strihkodov-whildberries',
        component: () => import('@/view/blog/PechatShtrihkodov.vue'),
    },
    {
        path: '/blog/postavki-po-qr-cody',
        name: 'postavki-po-qr-cody',
        component: () => import('@/view/blog/QrPostavki.vue'),
    },
    {
        path: '/blog/pechat-qr-i-strihkodov-whildberries',
        name: 'pechat-qr-i-strihkodov-whildberries',
        component: () => import('@/view/blog/PechatQrShtrihkodov.vue'),
    },
    {
        path: '/blog/ostatki-fbo',
        name: 'ostatki-fbo',
        component: () => import('@/view/blog/OstatkiFbo.vue'),
    },
]

export default routes